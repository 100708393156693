<template>
  <div id="otd_verification" >

    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
            btn-background="bg-gradient-success"
            :dark-mode="true"
          />
        </div>
      </div>
    </div>

    <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            
            <div class="row">  
              <div class="mt-8 mx-auto col-12 d-flex flex-column">
                <div class="card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h1 class="font-weight-bolder text-success text-gradient">One True Data</h1>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
    
              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h4 class="font-weight-bolder ">
                      01 / Verify Documents
                    </h4>
                    <p class="mb-0">You've received a Trakk document? Simply drag-and-drop it below to verify its origin and authenticity.</p>
                  </div>          
                </div>
              </div>

              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h4 class="font-weight-bolder">
                      02 /View The Audit Trail
                    </h4>
                    <p class="mb-0">Verification displays the activities and data embedded in a document. The status is always up to date, new activities added are visible in realtime.</p>
                  </div>          
                </div>
              </div>

              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h4 class="font-weight-bolder">
                      03 / Build Trust
                    </h4>
                    <p class="mb-0">Any action on a document is linked to a verified organisation on the Komgo Network. Only authorised user can register documents and add activities.</p>
                  </div>          
                </div>
              </div>
              
            </div>

            <div class="row mb-8">
    
              <div class="mx-auto col-12">
                <div class="card card-plain">
                  <div class="pb-0 card-header text-start">

                    {{loading? 'Loading...':null}}
                    
                    <div class="draddropcontent">

                      <div v-if="trackingData.length==0">

                        <div v-if="!file && !fileError">
                          <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                            <div class="dropZone-info" @drag="onChange">
                              <span class="fa fa-cloud-upload dropZone-title"></span>
                              <span class="dropZone-title mx-2">Drop file or click to upload</span>
                              <div class="dropZone-upload-limit-info">
                                <div>extension support: PDF</div>
                                <div>maximum file size: 5 MB</div>
                              </div>
                            </div>
                            <input type="file" @change="onChange">
                          </div>
                        </div>
                        <div v-else class="dropZone-uploaded">

                          <div class="dropZone-uploaded-info">

                            <div v-if="fileError">
                              <p class="dropZone-title"><strong>{{ fileErrorTitle }}</strong></p>
                              <p class="dropZone-title">{{ fileErrorMEssage }}</p>                          
                            </div>
                            <div v-else>
                              <span class="dropZone-title">{{ file.name?file.name:'- - -' }}</span>                         
                            </div>

                            <div class="d-flex">
                              <button type="button" class="btn btn-sm btn-round mt-2 mb-0 me-2 bg-gradient-dark removeFile"  @click="removeFile">Remove File</button>
                              <button type="button" class="btn btn-sm btn-round mt-2 mb-0 me-2 bg-gradient-dark removeFile"  @click="handleUpload" v-if="!fileError">Upload</button>
                            </div>
                            

                          </div>
                          
                        </div>
                      
                      </div>

                      <div class="row" v-else>

                        <div class="col-md-8">

                          <div v-if="!displayData">
                            Not select item!
                          </div>

                          <div v-else>


                            <div class="d-flex">
                              <div><i class='fa fa-file-o me-3' style="font-size:50px" ></i></div>
                              <div class="d-block">
                                <div class='font-weight-bold'>{{displayData.file? displayData.file : '- - -'}}</div>
                                
                                <div class='font-weight-bold small'>
                                  <div v-if="displayData.status=='ok'"><i class='fa fa-tag text-success me-2'></i>{{displayData.registered? displayData.registered : '- - -'}}</div>
                                  <div v-else><i class='fa fa-tag text-danger me-2'></i> Is not registered on Trakk, or has been tampered with.</div>                           
                              </div>
                            </div>
                            </div>
                            
                            

                            <div v-if="displayData.status=='ok'">

                              <timeline-list>

                                <TimelineItem v-for="(event, e_index) in displayData.time_line" :key="e_index"
                                  :color="event.color"
                                  :icon="event.icon"
                                  :title="event.title"
                                  :date-time="event.date_time"
                                />
                                
                              </timeline-list>

                            </div>
                            
                            <div v-else>

                              <div class="text-center mt-6 mb-6">
                                <div><i class='fa fa-info-circle me-3' style="font-size:50px" ></i></div>
                                <p class="dropZone-title"><strong>Audit trail not available</strong></p>
                                <p class="dropZone-title">This document is not registered on Trakk, or has been tampered with.</p>      
                                <p class="dropZone-title">If you believe this document is genuine, please contact the original owner/issuer to confirm.</p>    
                              </div>
                               
                            </div>

                          </div>
                          
                        </div>
                        <div  class="col-md-4">
                          <div class="my-4 ">
                            Document uploaded
                            <hr class="mt-0">

                            <div v-for="(item, index) in trackingData" :key="index" @click="displayItem(index)" class="d-flex">
                              <div><i class='fa fa-close hover me-3' style="cursor:pointer" @click="removeFile"></i></div>
                              
                              <div style="cursor:pointer">
                                <i :class="`fa fa-tag ${item.status=='ok'?'text-success':'text-danger'} me-1`"></i> {{ item.file ? item.file : '- - -' }}
                              </div>
                            </div>

               
                          </div>
                        </div>
                        
                      </div>

                    </div>
                    
                    <!--                   
                    <div class="small" v-if="file">
                      <div>fileName: {{ file.name?file.name:'- - -' }} | fileZise(bytes): {{ file.size?file.size:'- - -' }} | extension：{{ extension?extension:'- - -' }}</div>
                    </div> -->

                  </div>          
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </main>

  </div>
</template>

<script>
import Navbar from "@/examples/PageLayout/NavbarOtd.vue";
import { mapMutations } from "vuex";

import TimelineList from "../../components/TimelineList.vue";
import TimelineItem from "../../components/TimelineItem.vue";
import "./_style.scss";
// const body = document.getElementsByTagName("body")[0];
import axios from 'axios';

export default {
  name: "OtdVerification",
  components: {
    Navbar,
    TimelineList,
    TimelineItem,
  },
  data() {
    return {
      file: '',
      dragging: false,
      fileError: false,
      fileErrorTitle: '',
      fileErrorMEssage: '',
      displayData: null,
      trackingData: [],
      loading: false,

      // trackingDataExample: [
      //   {
      //     file: 'Example file test.pdf',
      //     registered: 'Registered on 22 Jan 2024 by CodeLoop',
      //     status: 'ok',
      //     time_line: [
      //       { color: "danger",  icon:"check-bold",  title:"Alert by",                               date_time:"21 DEC 9:34 PM" },
      //       { color: "info",    icon:"check-bold",  title:"Financed by",                            date_time:"21 DEC 9:30 PM" },
      //       { color: "info",    icon:"check-bold",  title:"Acknowledged",                           date_time:"18 DEC 4:54 AM" },
      //       { color: "info",    icon:"check-bold",  title:"Registered on 22 Jan 2024 by CodeLoop",  date_time:"22 JAN 2024" }
      //     ]
      //   }
      // ]
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    // body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    // body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      
      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match('application/pdf')) {        
        this.dragging         = false;
        this.fileError        = true;
        this.fileErrorTitle   = 'Wrong file format!';
        this.fileErrorMEssage = 'The document you’ve uploaded is in the wrong format. Only PDF documents can be uploaded and verified.';
        return;
      }
      
      if (file.size > 5000000) {
        this.dragging         = false;
        this.fileError        = true;
        this.fileErrorTitle   = 'Wrong file size!';
        this.fileErrorMEssage = 'Please check file size no over 5 MB.';
        return;
      }
      
      this.file = file;
      this.dragging = false;
    },
    removeFile() {
      this.file = '';
      this.fileError        = false;
      this.fileErrorTitle   = '';
      this.fileErrorMEssage = '';
      this.trackingData     = []
    },
    async handleUpload(){

      console.clear();
      console.log('=== Upload File ===');

      let self = this;

      self.fileError = false;
      self.fileErrorTitle = '';
      self.fileErrorMEssage = '';
      self.trackingData = []

      self.loading = true

      const formData = new FormData()
      formData.append('file', this.file)

      // 'http://hml.codeloop.com.br:7519/api/get-transactions'
      await axios.post(`https://core-bastion.codeloop.co/api/otd-verification`, formData).then(response => {
        console.log( response.data )
        if(response.data.status == 'success') {
          console.log( response.data.status )

          self.trackingData = response.data.data;
          self.displayItem(0);

        } else {
          self.fileError = true;
          self.fileErrorTitle = 'Sorry';
          self.fileErrorMEssage = 'Unable to perform this action at this time, please try again later';
        }

      }).catch(error =>{
        console.log(error.response.data);
          self.fileError = true;
          self.fileErrorTitle = 'Sorry';
          self.fileErrorMEssage = 'Unable to perform this action at this time, please try again later';
      }).finally(
        self.loading = false
      );



    },
    displayItem(index) {
      this.displayData = this.trackingData[index];

    }
  },
};
</script>
